import React from "react";
import theme from "../theme/3.0/theme";

const BaseChart = ({ children, options }) => (
    <div className={"convergence-chart"}>
        {children}
        <style jsx>{`
            .convergence-chart {
                position: relative;
                display: block;
                min-height: ${options && options.maxHeight
                    ? options.maxHeight.sm
                        ? options.maxHeight.sm
                        : options.maxHeight
                    : "400px"};
                max-height: ${options && options.maxHeight
                    ? options.maxHeight.sm
                        ? options.maxHeight.sm
                        : options.maxHeight
                    : "100%"};
            }

            @media (min-width: ${theme.breakpoint.md}) {
                .convergence-chart {
                    min-height: ${options && options.maxHeight
                        ? options.maxHeight.md
                            ? options.maxHeight.md
                            : options.maxHeight
                        : "350px"};
                    height: ${options && options.maxHeight
                        ? options.maxHeight.md
                            ? options.maxHeight.md
                            : options.maxHeight
                        : "auto"};
                }
            }

            @media (min-width: ${theme.breakpoint.lg}) {
                .convergence-chart {
                    min-height: ${options && options.maxHeight
                        ? options.maxHeight.lg
                            ? options.maxHeight.lg
                            : options.maxHeight
                        : "400px"};
                    height: ${options && options.maxHeight
                        ? options.maxHeight.lg
                            ? options.maxHeight.lg
                            : options.maxHeight
                        : "auto"};
                }
            }

            @media (min-width: ${theme.breakpoint.xl}) {
                .convergence-chart {
                    min-height: ${options && options.maxHeight
                        ? options.maxHeight.xl
                            ? options.maxHeight.xl
                            : options.maxHeight
                        : "450px"};
                    height: ${options && options.maxHeight
                        ? options.maxHeight.xl
                            ? options.maxHeight.xl
                            : options.maxHeight
                        : "auto"};
                }
            }
        `}</style>
    </div>
);

export default BaseChart;
