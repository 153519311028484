import gql from "graphql-tag";

export const GET_STATS_QUERY = gql`
    query StatsQuery {
        stats {
            historicalDealSummaryAnalysis
            investorSummaryAnalysis
            institutionSummaryAnalysis
            dealSummaryAnalysis
        }
    }
`;
