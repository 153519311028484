/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import React from "react";
import BarChart from "../../../../chart/BarChart";
import Loader from "../../../../layout/Loader";
import BaseWidget from "../../../deals/SummaryAnalysis/Widget/BaseWidget";
import { generateColourGradientArray } from "../../../../../utils/CommonUtils";

type Props = {
    data;
    loading: boolean;
    title;
    gradientColors?: [string, string];
};
class MostActiveDevelopmentAgencies extends React.Component<Props> {
    render() {
        const { data, loading, title, gradientColors } = this.props;

        return (
            <BaseWidget
                data={{ data, loading }}
                render={({ data, loading }) => {
                    if (loading) {
                        return <Loader />;
                    }

                    if (!data || data.length <= 0) {
                        return (
                            <p className={"text-center"}>
                                No information to display.
                            </p>
                        );
                    }

                    data.sort((a, b) => b.value - a.value);

                    const chartData = [];
                    data.map((row) => {
                        chartData.push({
                            x: row.key,
                            y: row.value
                        });
                    });

                    const colors = gradientColors
                        ? generateColourGradientArray(
                              ...gradientColors,
                              Object.keys(data).length
                          )
                        : generateColourGradientArray(
                              "#26a4d9",
                              "#10283c",
                              Object.keys(data).length
                          );

                    return (
                        <BarChart
                            title={
                                title
                                    ? title
                                    : "Most Active Development Agencies"
                            }
                            id={"most-active-development-agencies"}
                            data={[
                                {
                                    name: "Number of Investments",
                                    data: chartData
                                }
                            ]}
                            xAxis={{
                                title: "Number of Investments"
                            }}
                            colors={colors}
                            horizontal={true}
                            options={{
                                source: "Convergence",
                                maxHeight: "450px"
                            }}
                        />
                    );
                }}
            />
        );
    }
}

export default MostActiveDevelopmentAgencies;
