import React, { ReactNode } from "react";
import theme from "./theme";

type WidgetContainerProps = {
    children;
    skipMediumBreakpoint?;
};

const WidgetContainer = ({
    children,
    skipMediumBreakpoint
}: WidgetContainerProps) => {
    const desktopBreakpoint = skipMediumBreakpoint
        ? theme.breakpoint.lg
        : theme.breakpoint.md;
    return (
        <div>
            {children}
            <style jsx>{`
                 {
                    display: flex;
                    flex-flow: column nowrap;
                    margin-right: -15px;
                    margin-left: -15px;
                    margin-bottom: 2rem;
                }

                :last-of-type {
                    margin-bottom: 0;
                }

                @media (min-width: ${desktopBreakpoint}) {
                     {
                        flex-flow: row wrap;
                    }
                }

                @media (min-width: ${theme.breakpoint.xl}) {
                     {
                        flex-flow: row wrap;
                    }
                }
            `}</style>
        </div>
    );
};
const Widget = ({ children }) => (
    <div>
        {children}
        <style jsx>{`
             {
                display: flex;
                position: relative;
                flex-direction: column;
                min-width: 0;
                word-wrap: break-word;
                background-color: ${theme.colour.white};
                border-radius: 8px;
                border: 1px solid ${theme.colour.gray400};
                margin-left: 15px;
                margin-right: 15px;
                margin-bottom: 1rem;
            }

            @media (min-width: ${theme.breakpoint.md}) {
                 {
                    flex: 1 0 0%;
                    margin-bottom: 0;
                }
            }

            @media (min-width: ${theme.breakpoint.lg}) {
                 {
                    flex: 1 0 0%;
                    margin-bottom: 0;
                }
            }

            @media (min-width: ${theme.breakpoint.xl}) {
                 {
                    flex: 1 0 0%;
                    margin-bottom: 0;
                }
            }
        `}</style>
    </div>
);

const WidgetBody = ({ children }) => (
    <div>
        {children}
        <style jsx>{`
             {
                padding: 1rem;
            }
        `}</style>
    </div>
);

type WidgetTextProps = {
    children;
    disabled?;
};

const WidgetText = ({ children, disabled }: WidgetTextProps) => (
    <div>
        {children}
        <style jsx>{`
            div > :global(.chart-container) {
                overflow-y: auto;
            }
            div {
                height: ${disabled ? "100%" : "auto"};
            }
        `}</style>
    </div>
);

const WidgetFooter = ({ children }) => <div>{children}</div>;

const WidgetTitle = ({ children }) => (
    <div>
        {children}
        <style jsx>{`
             {
                text-align: center;
                font-size: 1.1rem;
            }
        `}</style>
    </div>
);

const WidgetLargeNumber = ({ color, children }: any) => (
    <div>
        {children}
        <style jsx>{`
             {
                text-align: center;
                font-size: 2rem;
                color: ${color || theme.colour.primary};
            }
        `}</style>
    </div>
);
type Props = {
    title: string;
    body: ReactNode;
    footer?: ReactNode;
};
const BasicWidget = ({ title, body, footer }: Props) => (
    <Widget>
        <WidgetBody>
            {title && <WidgetTitle>{title}</WidgetTitle>}
            {body && <WidgetText>{body}</WidgetText>}
        </WidgetBody>
        {footer && <WidgetFooter>{footer}</WidgetFooter>}
    </Widget>
);

export {
    WidgetContainer,
    Widget,
    WidgetBody,
    WidgetFooter,
    WidgetTitle,
    WidgetText,
    WidgetLargeNumber,
    BasicWidget
};
