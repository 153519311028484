import React from "react";
import dynamic from "next/dynamic";
import BaseChart from "./BaseChart";
import theme from "../theme/3.0/theme";
import { convertToHumanReadableString } from "../../utils/CommonUtils";
import { ApexOptions } from "apexcharts";

const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });

const formatter = (value, format) => {
    switch (format) {
        case "percentage": {
            return typeof value === "number" ? `${value}%` : value;
        }
        case "currency": {
            return typeof value === "number"
                ? `${convertToHumanReadableString(value)}`
                : value;
        }
        default:
            return value;
    }
};
type Props = {
    id?;
    xAxis?;
    yAxis?;
    title?;
    horizontal?;
    data;
    percentage?;
    currency?;
    colors;
    options;
};
const BarChart = ({
    id,
    xAxis,
    yAxis,
    title,
    horizontal,
    data,
    percentage,
    currency,
    colors,
    options
}: Props) => {
    let format = null;
    if (percentage) {
        format = "percentage";
    }

    if (currency) {
        format = "currency";
    }

    const chartOptions: ApexOptions = {
        chart: {
            id:
                typeof id === "undefined"
                    ? title.replace(/\s/g, "-").toLowerCase()
                    : id,
            width: "100%",
            toolbar:
                typeof options.toolbar !== "undefined"
                    ? options.toolbar
                    : {
                          tools: {
                              download: true
                          }
                      },
            animations: {
                enabled: false
            },
            events: {
                mounted: (chart) => {
                    chart.windowResizeHandler();
                },
                click: (...click) => console.debug({ click }),
                legendClick: (...legendClick) => console.debug({ legendClick }),
                markerClick: (...markerClick) => console.debug({ markerClick }),
                xAxisLabelClick: (...xAxisLabelClick) =>
                    console.debug({ xAxisLabelClick })
            }
        },
        responsive: [
            {
                breakpoint: 992,
                options: {
                    chart: {
                        toolbar: {
                            show: false
                        }
                    },
                    legend: {
                        position: "bottom"
                    },
                    title: {
                        style: {
                            fontSize: "16px",
                            fontWeight: "normal"
                        }
                    }
                }
            }
        ],
        plotOptions: {
            bar: {
                distributed:
                    typeof options.distributed !== "undefined"
                        ? options.distributed
                        : true,
                horizontal: horizontal ? true : false
            }
        },
        dataLabels: {
            enabled:
                typeof options.dataLabels === "undefined"
                    ? true
                    : options.dataLabels,
            dropShadow: {
                enabled: true
            },
            // @ts-ignore POSSIBLE ERROR FOUND
            formatter: (value, index) => formatter(value, format, index)
        },
        colors,
        tooltip: {
            y: {
                // @ts-ignore POSSIBLE ERROR FOUND
                formatter: (value, index) => formatter(value, format, index)
            }
        },
        yaxis: {
            labels: {
                show: true,
                // @ts-ignore POSSIBLE ERROR FOUND
                formatter: (value, index) => formatter(value, format, index)
            }
        },
        xaxis: {
            labels: {
                // @ts-ignore POSSIBLE ERROR FOUND
                formatter: (value, index) => formatter(value, format, index)
            }
        },
        grid: {
            xaxis: {
                lines: {
                    show: horizontal
                }
            },
            yaxis: {
                lines: {
                    show: !horizontal
                }
            }
        },
        ...(options.legend ? { legend: options.legend } : {}),
        ...(options.title ? { title: options.title } : {})
    };

    if (title) {
        chartOptions.title = {
            text: title,
            align: "center",
            style: {
                fontSize: "20px",
                fontWeight: "normal"
            }
        };
        if (options) {
            chartOptions.subtitle = {
                text: `${options.source ? `Source: ${options.source}` : ""} ${
                    options.copyright ? "© Convergence" : ""
                }`,
                align: "center",
                style: {
                    color: theme.colour.gray400
                },
                margin: 0,
                floating: true
            };
        }
    }

    if (xAxis) {
        if (xAxis.labels) {
            chartOptions.xaxis.categories = xAxis.labels;
        }

        if (xAxis.title) {
            chartOptions.xaxis.title = {
                text: xAxis.title,
                offsetX: 10,
                style: {
                    color: theme.colour.gray400,
                    fontSize: "13px"
                }
            };
        }
    }

    if (yAxis) {
        if (yAxis.title) {
            // @ts-ignore POSSIBLE ERROR FOUND
            chartOptions.yaxis.title = {
                text: yAxis.title,
                offsetX: 10,
                style: {
                    color: theme.colour.gray400,
                    fontSize: "13px"
                }
            };
        }
    }

    return (
        <BaseChart options={options}>
            <Chart
                type={"bar"}
                options={chartOptions}
                series={data}
                height={options && options.maxHeight ? "100%" : "100%"}
            />
        </BaseChart>
    );
};

export default BarChart;
