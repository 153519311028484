/* eslint-disable react/display-name */
import React from "react";
import { generateColourGradientArray } from "../../../../utils/CommonUtils";
import DonutChart from "../../../chart/DonutChart";

export default ({ numberOfUniqueInvestorsBySector }) => {
    const total = numberOfUniqueInvestorsBySector
        ? numberOfUniqueInvestorsBySector.reduce(
              (accumulator, currentValue) => accumulator + currentValue.value,
              0
          )
        : 0;
    return (
        <DonutChart
            id={"convergence-percentage-of-transactions-by-investor-sectors"}
            title={"Investor Sectors"}
            labels={numberOfUniqueInvestorsBySector.map((val) => val.key)}
            data={numberOfUniqueInvestorsBySector.map((val) =>
                Math.round((val.value / total) * 100)
            )}
            // @ts-ignore
            xAxis={{
                title:
                    "Proportion of blended finance transactions by target countries"
            }}
            colors={generateColourGradientArray(
                "#337ab7",
                "#10283c",
                numberOfUniqueInvestorsBySector.length
            )}
            horizontal={true}
            percentage={true}
            options={{
                source: "Convergence",
                copyright: true,
                maxHeight: {
                    sm: "250px",
                    md: "450px",
                    lg: "450px",
                    xl: "450px"
                }
            }}
        />
    );
};
