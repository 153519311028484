/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import faSync from "@fortawesome/fontawesome-free-solid/faSync";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import {
    WidgetBody,
    WidgetFooter,
    WidgetTitle,
    WidgetText,
    Widget
} from "../../../../theme/3.0/Widget";

const BaseWidget = ({ title, render, onClickRefresh, baseContent, data }) => (
    <Widget>
        <WidgetBody>
            {title && <WidgetTitle>{title}</WidgetTitle>}
            <WidgetText>{render(data)}</WidgetText>
        </WidgetBody>
        {(baseContent || onClickRefresh) && (
            <WidgetFooter>
                <div className="d-flex align-items-center justify-content-between">
                    {baseContent && <small>{baseContent}</small>}
                    {onClickRefresh && (
                        <button
                            className={"btn btn-sm btn-link"}
                            onClick={onClickRefresh}
                        >
                            <FontAwesomeIcon icon={faSync as IconProp} />
                        </button>
                    )}
                </div>
            </WidgetFooter>
        )}
    </Widget>
);

BaseWidget.propTypes = {
    render: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    title: PropTypes.any,
    onClickRefresh: PropTypes.func,
    baseContent: PropTypes.any
};

export default BaseWidget;
