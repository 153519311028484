/* eslint-disable react/display-name */
import React from "react";
import { generateColourGradientArray } from "../../../../utils/CommonUtils";
import DonutChart from "../../../chart/DonutChart";

export default ({ numberOfDealsByType }) => {
    const total = numberOfDealsByType
        ? numberOfDealsByType.reduce(
              (accumulator, currentValue) => accumulator + currentValue.value,
              0
          )
        : 0;
    return (
        <DonutChart
            id={"convergence-percentage-of-transactions-by-vehicle-type"}
            title={"Vehicle Types"}
            labels={numberOfDealsByType.map((val) => val.key)}
            data={numberOfDealsByType.map((val) =>
                Math.round((val.value / total) * 100)
            )}
            // @ts-ignore
            xAxis={{
                title:
                    "Percentage of blended finance transactions by vehicle type"
            }}
            colors={generateColourGradientArray(
                "#337ab7",
                "#10283c",
                Object.keys(numberOfDealsByType).length
            )}
            percentage={true}
            options={{
                source: "Convergence",
                copyright: true,
                maxHeight: {
                    sm: "250px",
                    md: "450px",
                    lg: "450px",
                    xl: "450px"
                }
            }}
        />
    );
};
