import { ApexOptions } from "apexcharts";
import dynamic from "next/dynamic";
import React from "react";
import theme from "../theme/3.0/theme";
import BaseChart from "./BaseChart";

const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });

type Props = {
    id;
    labels;
    title?: string;
    data;
    colors;
    options;
};

const DonutChart = ({ id, labels, title, data, colors, options }: Props) => {
    const chartOptions: ApexOptions = {
        chart: {
            id:
                typeof id === "undefined"
                    ? title.replace(/\s/g, "-").toLowerCase()
                    : id,
            toolbar: {
                show: true
            },
            animations: {
                enabled: false
            },
            events: {
                mounted: (chart) => {
                    chart.windowResizeHandler();
                }
            }
        },
        responsive: [
            {
                breakpoint: 1024,
                options: {
                    chart: {
                        toolbar: {
                            show: false
                        }
                    },
                    legend: {
                        position: "bottom"
                    },
                    title: {
                        style: {
                            fontSize: "16px",
                            fontWeight: "normal"
                        }
                    }
                }
            }
        ],
        dataLabels: {
            enabled: true,
            dropShadow: {
                enabled: true
            }
        },
        legend: {
            position: "top",
            offsetY: 5
        },
        colors
    };

    if (title) {
        chartOptions.title = {
            text: title,
            align: "center",
            style: {
                fontSize: "20px",
                fontWeight: "normal"
            }
        };
        if (options) {
            chartOptions.subtitle = {
                text: `${options.source ? `Source: ${options.source}` : ""} ${
                    options.copyright ? "© Convergence" : ""
                }`,
                align: "center",
                style: {
                    color: theme.colour.gray400
                }
            };
        }
    }

    if (labels) {
        chartOptions.labels = labels;
    }

    return (
        <BaseChart options={options}>
            <Chart
                options={chartOptions}
                series={data}
                type={"donut"}
                width={"100%"}
                height={options && options.maxHeight ? "100%" : ""}
            />
        </BaseChart>
    );
};

export default DonutChart;
