import React from "react";
import dynamic from "next/dynamic";
import BaseChart from "./BaseChart";
import theme from "../theme/3.0/theme";
import { formatLargeNumber } from "../../utils/CommonUtils";
import { ApexOptions } from "apexcharts";

const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });

const LineColumnChart = ({ id, xAxis, title, data, options, colors }) => {
    const chartOptions: ApexOptions = {
        chart: {
            id:
                typeof id === "undefined"
                    ? title.replace(/\s/g, "-").toLowerCase()
                    : id,
            // stacked: true, <-breaks things
            animations: {
                enabled: false
            },
            events: {
                mounted: (chart) => {
                    chart.windowResizeHandler();
                }
            }
        },
        responsive: [
            {
                breakpoint: 1024,
                options: {
                    chart: {
                        height: 350,
                        toolbar: {
                            show: false
                        }
                    },
                    legend: {
                        position: "bottom"
                    },
                    title: {
                        style: {
                            fontSize: "16px"
                        }
                    },
                    dataLabels: {
                        enabled: false
                    }
                }
            }
        ],
        plotOptions: {
            bar: {
                dataLabels: {
                    position: "bottom"
                }
            }
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [0],
            dropShadow: {
                enabled: true
            },
            formatter: (value, { seriesIndex }) => {
                return seriesIndex === 2
                    ? `$${formatLargeNumber(value)}`
                    : value.toString(); // TS CODE CHANGE value is a number
            },
            style: {
                colors: ["#FFFFFF"]
            }
        },
        stroke: {
            width: [0, 0, 4]
        },
        tooltip: {
            y: {
                formatter: (value, { seriesIndex }) => {
                    return seriesIndex === 1
                        ? `$${formatLargeNumber(value)}`
                        : value.toString(); // TS CODE CHANGE value is a number
                }
            }
        },
        yaxis: [
            {
                opposite: true,
                axisTicks: {
                    show: true,
                    color: "#337ab7"
                },
                axisBorder: {
                    show: true,
                    color: "#337ab7"
                },
                labels: {
                    style: {
                        // @ts-ignore POSSIBLE ERROR FOUND: only colors? exists
                        color: "#337ab7"
                    }
                },
                title: {
                    text: "Number of Transactions Closed",
                    style: {
                        color: "#337ab7",
                        fontWeight: "normal"
                    }
                },
                tooltip: {
                    enabled: false
                }
            },
            {
                seriesName:
                    "Number of Transactions Closed (with insufficient publicly-available information)",
                title: {
                    // @ts-ignore POSSIBLE ERROR FOUND. no show
                    show: false
                },
                labels: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                axisBorder: {
                    show: false
                },
                forceNiceScale: false,
                max: {
                    // @ts-ignore
                    formatter: (value) =>
                        value === 0 ? "" : formatLargeNumber(value, false)
                }
            },
            {
                seriesName: "Total Capital Committed",
                axisTicks: {
                    show: true,
                    color: "#333333"
                },
                axisBorder: {
                    show: true,
                    color: "#333333"
                },
                labels: {
                    style: {
                        // @ts-ignore POSSIBLE ERROR FOUND. only colors? exists
                        color: "#333333"
                    },
                    formatter: (value) => {
                        return `$${
                            value === 0 ? "" : formatLargeNumber(value, false)
                        }`;
                    }
                },
                title: {
                    text: "Total Capital Committed (Billions)",
                    style: {
                        color: "#333333"
                    }
                }
            }
        ],
        grid: {
            xaxis: {
                lines: {
                    show: true
                }
            },
            yaxis: {
                lines: {
                    show: false
                }
            }
        },
        legend: {
            show: false
        },
        colors
    };

    if (title) {
        chartOptions.title = {
            text: title,
            align: "center",
            style: {
                fontSize: "20px"
            }
        };
        if (options) {
            chartOptions.subtitle = {
                text: `${options.source ? `Source: ${options.source}` : ""} ${
                    options.copyright ? "© Convergence" : ""
                }`,
                align: "center",
                style: {
                    color: theme.colour.gray400
                }
            };
        }
    }

    if (xAxis) {
        if (xAxis.labels) {
            chartOptions.labels = xAxis.labels;
        }
    }

    return (
        <BaseChart options={options}>
            <Chart options={chartOptions} series={data} type={"line"} />
        </BaseChart>
    );
};

export default LineColumnChart;
