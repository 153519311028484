/* eslint-disable react/display-name */
import React from "react";
import BarChart from "../../../chart/BarChart";
import { generateColourGradientArray } from "../../../../utils/CommonUtils";

export default ({ dealSizeByRange }) => {
    const total = dealSizeByRange
        ? dealSizeByRange.reduce(
              (accumulator, currentValue) => accumulator + currentValue.value,
              0
          )
        : 0;
    return (
        <BarChart
            id={"convergence-bf-transactions-by-total-size"}
            title={"Deal Sizes"}
            data={[
                {
                    name: "Percentage of blended finance transactions",
                    data: dealSizeByRange.map((val) =>
                        Math.round((val.value / total) * 100)
                    )
                }
            ]}
            xAxis={{
                labels: dealSizeByRange.map((val) => val.key),
                title:
                    "Percentage of blended finance transactions by total size"
            }}
            colors={generateColourGradientArray("#337ab7", "#10283c", 9)}
            horizontal={true}
            percentage={true}
            options={{
                source: "Convergence",
                copyright: true,
                maxHeight: {
                    sm: "400px",
                    md: "450px",
                    lg: "450px",
                    xl: "450px"
                }
            }}
        />
    );
};
