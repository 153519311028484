/* eslint-disable react/display-name */
import React from "react";
import { generateColourGradientArray } from "../../../../utils/CommonUtils";
import BarChart from "../../../chart/BarChart";

export default ({ numberOfDealsBySDG }) => {
    return (
        <React.Fragment>
            <BarChart
                id={"convergence-bf-transactions-by-sdg-frequency"}
                title={"SDG Frequency"}
                data={[
                    {
                        name: "Percentage of blended finance transactions",
                        data: numberOfDealsBySDG.map((val) => ({
                            x: val.key,
                            y: val.value
                        }))
                    }
                ]}
                xAxis={{
                    title:
                        "Proportion of blended finance transactions by target countries"
                }}
                colors={generateColourGradientArray(
                    "#337ab7",
                    "#10283c",
                    numberOfDealsBySDG.length
                )}
                horizontal={true}
                percentage={true}
                options={{
                    source: "Convergence",
                    copyright: true
                }}
            />
        </React.Fragment>
    );
};
