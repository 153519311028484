import faQuoteLeft from "@fortawesome/fontawesome-free-solid/faQuoteLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import CountUp from "react-countup";
import { Nav } from "reactstrap";
import Authenticated from "../components/Authenticated";
import DonutChart from "../components/chart/DonutChart";
import LineColumnChart from "../components/chart/LineColumnChart";
import GeneralPage from "../components/layout/GeneralPage";
import ScrollSpy from "../components/layout/ScrollSpy";
import Spinner from "../components/layout/Spinner";
import Sticky from "../components/layout/Sticky";
import DealSizesByRange from "../components/page/blended-finance-101/Chart/DealSizesByRange";
import NumberOfDealsByBlendingArchetypes from "../components/page/blended-finance-101/Chart/NumberOfDealsByBlendingArchetypes";
import NumberOfDealsByCountry from "../components/page/blended-finance-101/Chart/NumberOfDealsByCountry";
import NumberOfDealsByRegion from "../components/page/blended-finance-101/Chart/NumberOfDealsByRegion";
import NumberOfDealsBySDG from "../components/page/blended-finance-101/Chart/NumberOfDealsBySDG";
import NumberOfDealsBySector from "../components/page/blended-finance-101/Chart/NumberOfDealsBySector";
import NumberOfDealsByVehicleType from "../components/page/blended-finance-101/Chart/NumberOfDealsByVehicleType";
import NumberOfInvestorsBySector from "../components/page/blended-finance-101/Chart/NumberOfInvestorsBySector";
import TopPhilanthropicInvestors from "../components/page/blended-finance-101/Chart/TopPhilanthropicInvestors";
import TopPrivateInvestors from "../components/page/blended-finance-101/Chart/TopPrivateInvestors";
import TopPublicInvestorsWithACommercialMandate from "../components/page/blended-finance-101/Chart/TopPublicInvestorsWithACommercialMandate";
import TopPublicInvestorsWithADevelopmentMandate from "../components/page/blended-finance-101/Chart/TopPublicInvestorsWithADevelopmentMandate";
import Link from "../components/theme/3.0/Link";
import { BRAND_COLOURS } from "../constants";
import { GET_STATS_QUERY } from "../graphql/query/StatsQuery";
import { authenticateUser } from "../utils/AuthenticationUtility";
import {
    convertToCommandSeparatedList,
    formatLargeNumber,
    generateColourGradientArray
} from "../utils/CommonUtils";
import { withApollo } from "../utils/WithApollo";
import NumberOfInvestorsByNumberOfDeals from "../components/page/blended-finance-101/Chart/NumberOfInvestorsByNumberOfDeals";
import theme from "../components/theme/3.0/theme";
import ApolloClient from "apollo-client";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ScrollingNavItem } from "../components/layout/Menu/ScrollingNavItem";
import MostActiveDevelopmentAgencies from "../components/page/investor-data/SummaryAnalysis/Widget/MostActiveDevelopmentAgencies";
import MostActiveMDBsAndDFIs from "../components/page/investor-data/SummaryAnalysis/Widget/MostActiveMDBsAndDFIs";

const navLinks = [
    {
        hash: "definition",
        label: "Definition",
        active: true
    },
    {
        hash: "importance",
        label: "Importance",
        active: false
    },
    {
        hash: "characteristics",
        label: "Characteristics",
        active: false
    },
    {
        hash: "archetypes",
        label: "Archetypes",
        active: false
    },
    {
        hash: "market-size",
        label: "Market Size",
        active: false
    },
    {
        hash: "deal-sizes-and-types",
        label: "Deal Sizes and Types",
        active: false
    },
    {
        hash: "blending-trends",
        label: "Blending Trends",
        active: false
    },
    {
        hash: "regions",
        label: "Regions",
        active: false
    },
    {
        hash: "sectors",
        label: "Sectors",
        active: false
    },
    {
        hash: "sdgs-and-impact",
        label: "SDGs and Impact",
        active: false
    },
    {
        hash: "investors-overall",
        label: "Investors",
        active: false
    },
    {
        hash: "private-investors",
        label: "Private Investors",
        active: false
    },
    {
        hash: "public-investors",
        label: "Public Investors",
        active: false
    },
    {
        hash: "philanthropic-investors",
        label: "Philanthropic Investors",
        active: false
    },
    {
        hash: "learn-more",
        label: "Learn More",
        active: false
    }
];

const sdgFundingGap = {
    labels: [
        "Infrastructure",
        "Climate change",
        "Food security",
        "Education",
        "Health"
    ],
    data: [1100, 800, 300, 300, 100]
};

const growthOfBlendingFinanceActivities = {
    labels: [
        2011,
        2012,
        2013,
        2014,
        2015,
        2016,
        2017,
        2018,
        2019,
        2020,
        2021,
        2022
    ],
    dataBar: [181, 225, 270, 323, 378, 432, 498, 558, 612, 677, 739, 759],
    dataLine: [
        41403560000,
        50730000000,
        59359080000,
        79953591428,
        98562189305,
        115323179110,
        128453511493,
        144360711493,
        155097088741,
        160994870003,
        168138279955,
        169528354955
    ]
};

// fresh 2022 OCT 25 data. Implemented hardcoded above ^^^^^
// Year, Deal count (all), Annual financing (all deals)
// 2011    44    5,427,725,000
// 2012    45    9,326,440,000
// 2013    53    8,629,080,000
// 2014    55    20,594,511,428
// 2015    54    18,608,597,877
// 2016    66    16,760,989,805
// 2017    60    13,130,332,383
// 2018    54    15,907,200,000
// 2019    65    10,736,377,248
// 2020    62    5,897,781,262
// 2021    20    7,143,409,952
// 2022    20    1,390,075,000

type Props = {
    stats;
    pageTitle: string;
    description: string;
};
class BlendedFinance extends Component<Props> {
    /**
     * Get our initial props for our authentication checks.
     * @returns {Promise<void>}
     */
    static async getInitialProps(ctx) {
        await authenticateUser(ctx.apolloClient, ctx, undefined);

        // TS CODE CHANGE from {} to {data: null}
        let response = { data: null };

        try {
            response = await (ctx.apolloClient as ApolloClient<any>).query({
                query: GET_STATS_QUERY
            });
        } catch (e) {
            response = { data: null };
        }

        let stats =
            response.data && response.data.stats ? response.data.stats : {};

        return {
            stats,
            pageTitle: "Blended Finance",
            description:
                "Everything you need to know about the blended finance market, including definitions, market size, and trends around blending approaches, regions, sectors, SDGs, impact, and investors."
        };
    }

    render() {
        const { stats } = this.props;

        let loading = false;
        if (Object.keys(stats).length <= 0) {
            loading = true;
        }

        return (
            <Authenticated passNullUser enableUserChecks>
                <style jsx global>
                    {`
                        section {
                            padding: 10px;
                        }

                        .pageTitle {
                            font-size: 2em;
                            margin-bottom: 1rem;
                        }

                        .title {
                            margin-bottom: 1rem;
                            font-size: 1.4rem;
                            font-weight: bold;
                            text-transform: uppercase;
                        }

                        .introduction {
                            margin-top: 0;
                        }

                        .grey-background {
                            background-color: ${BRAND_COLOURS.light};
                        }

                        .definition {
                            background: #f9f9f9;
                            border-left: 10px solid #ccc;
                            padding: 10px;
                            font-size: 1.3em;
                        }

                        .quote {
                            margin-right: 10px;
                            color: #575757;
                        }

                        #sdgs-poster {
                            width: 100%;
                            margin-top: 30px;
                        }

                        #blended-finance-definition {
                            display: block;
                            margin: 10px auto;
                            max-height: 400px;
                        }
                        .content-img {
                            max-width: 100%;
                        }

                        .number {
                            font-size: 300%;
                            text-align: center;
                            color: #14588c;
                        }
                        .chart-title {
                            text-align: center;
                            text-transform: uppercase;
                            margin-bottom: 20px;
                        }
                        .chart-wrapper {
                            background-color: #f8f9fa;
                            border: 0.5px solid #e9ecef;
                            padding: 20px 0 10px 0;
                            min-height: 250px;
                        }
                        .archetypeImage {
                            max-width: 50%;
                            max-height: 100%;
                            margin-bottom: 10px;
                        }
                        .archetypeBox {
                            padding: 10px 10px 10px 0;
                        }

                        .chart {
                            position: relative;
                        }

                        @media (min-width: ${theme.breakpoint.md}) {
                            .chart {
                                margin-bottom: 4rem;
                            }
                        }

                        @media (min-width: ${theme.breakpoint.lg}) {
                            .chart {
                                margin-bottom: 4rem;
                            }
                        }

                        @media (min-width: ${theme.breakpoint.xl}) {
                            .chart {
                                margin-bottom: 4rem;
                            }
                        }
                        :target {
                            scroll-margin-top: 99px;
                        }

                        @media (min-width: ${theme.breakpoint.md}) {
                            :target {
                                scroll-margin-top: 90px;
                            }
                        }

                        .make-sticky {
                            position: sticky;
                            top: 3.75rem;
                            background-color: white;
                            z-index: 2;
                        }

                        @media (min-width: ${theme.breakpoint.md}) {
                            .make-sticky {
                                top: 4.333333rem;
                            }
                        }
                        #content {
                            margin-top: -90px;
                        }
                        #content > div:first-child {
                            padding-top: 117px !important;
                        }
                    `}
                </style>
                <GeneralPage
                    pageTitle={this.props.pageTitle}
                    description={this.props.description}
                >
                    <div className="row px-3">
                        <div className="col-sm-12 col-md-3 order-md-1 make-sticky">
                            <Sticky
                                containerStyle={{
                                    top: "4.333333rem",
                                    display: "flex",
                                    width: "100%",
                                    overflow: "auto"
                                }}
                            >
                                {() => (
                                    <Nav className="table-of-contents">
                                        <ScrollSpy
                                            navLinks={navLinks}
                                            render={ScrollingNavItem}
                                        />
                                    </Nav>
                                )}
                            </Sticky>
                        </div>
                        <article className="col-sm-12 col-md-9 order-md-2 top-8">
                            <section>
                                <h2 className="pageTitle">Blended Finance</h2>
                                <p className="mb-3 introduction" id="overview">
                                    Welcome to Convergence's blended finance
                                    primer. This primer uses Convergence's
                                    database of historical blended finance
                                    transactions to generate unique insights
                                    about the blended finance market to date.
                                    Convergence's data methodology can be viewed{" "}
                                    <Link
                                        routeKey={"about-data-methodology"}
                                        label={"here"}
                                        target={"_blank"}
                                    />
                                    .
                                </p>
                            </section>

                            <section className="mb-4" id="definition">
                                <h4 className="section-title mb-3 tw-text-2xl">
                                    Definition
                                </h4>

                                <div className="definition">
                                    <span className="quote">
                                        <FontAwesomeIcon
                                            className="fa-lg"
                                            icon={faQuoteLeft as IconProp}
                                        />
                                    </span>
                                    Blended finance is the use of catalytic
                                    capital from public or philanthropic sources
                                    to increase private sector investment in
                                    sustainable development.
                                </div>
                                <img
                                    id="blended-finance-definition"
                                    className="content-img"
                                    src="/static/blended-finance-definition.jpg"
                                    alt="blended-finance-definition"
                                />
                                <div className="mb-3">
                                    <b>
                                        Blended finance is a structuring
                                        approach
                                    </b>{" "}
                                    that allows organizations with different
                                    objectives to invest alongside each other
                                    while achieving their own objectives
                                    (whether financial return, social impact, or
                                    a blend of both). The main investment
                                    barriers for private investors addressed by
                                    blended finance are (i) high perceived and
                                    real risk and (ii) poor returns for the risk
                                    relative to comparable investments. Blended
                                    finance creates investable opportunities in
                                    developing countries which leads to more
                                    development impact.
                                </div>

                                <div className="mb-3">
                                    <b>
                                        Blended finance is not an investment
                                        approach, instrument, or end solution.
                                    </b>{" "}
                                    It is also different from impact investing.
                                    Impact investing is an investment approach,
                                    and impact investors often participate in
                                    blended finance structures.
                                </div>

                                <div className="mb-3">
                                    <b>
                                        Convergence focuses on blended finance
                                        in developing countries.
                                    </b>{" "}
                                    Developing countries face significant
                                    challenges including low levels of access to
                                    safe drinking water, sanitation and hygiene;
                                    energy poverty; high levels of pollution;
                                    high rates of tropical and infectious
                                    diseases; and a lack of physical
                                    infrastructure. Blended finance can create
                                    investment opportunities in developing
                                    countries, crowding in additional private
                                    sector funds in volumes never before seen.
                                </div>

                                <div className="mb-3">
                                    <b>
                                        Convergence focuses on blended finance
                                        to catalyze private investment.
                                    </b>{" "}
                                    Other important stakeholders and
                                    initiatives, such as the{" "}
                                    <a
                                        href="http://www.oecd.org/dac/financing-sustainable-development/development-finance-topics/blended-finance.htm"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Organization for Economic Co-operation
                                        and Development (OECD)
                                    </a>{" "}
                                    and the{" "}
                                    <a
                                        href="https://www.adb.org/documents/dfi-blended-concessional-finance-report-2018"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        DFI Working Group on Blended
                                        Concessional Finance for Private Sector
                                        Projects
                                    </a>{" "}
                                    focus on a broader scope of blended finance
                                    that includes the use of development capital
                                    to mobilize commercial-development
                                    orientated public capital (e.g., capital
                                    from development finance institutions).
                                    Convergence works closely with the OECD, DFI
                                    Working Group, and others to coordinate
                                    blended finance activity.
                                </div>
                            </section>

                            <section className="mb-4" id="importance">
                                <h4 className="section-title mb-3 tw-text-2xl">
                                    Importance
                                </h4>

                                <div className="mb-3">
                                    <b>
                                        The{" "}
                                        <a
                                            href="https://sustainabledevelopment.un.org"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Sustainable Development Goals
                                        </a>{" "}
                                        (SDGs)
                                    </b>{" "}
                                    are a set of 17 Global Goals set by the
                                    United Nations (UN) that aim to tackle a
                                    range of issues, from combating climate
                                    change to ending poverty and hunger. Not
                                    only do the SDGs aim to create a world that
                                    is more sustainable, they also offer real
                                    business opportunities.
                                </div>
                                <div className="mb-3">
                                    <b>
                                        To achieve the SDGs, a significant
                                        scale-up of investment is required
                                        today.
                                    </b>{" "}
                                    Current levels of development financing are
                                    not sufficient, with an estimated $4.2
                                    trillion funding gap per annum to realize
                                    the SDGs in developing countries alone.
                                </div>
                                <div className="mb-3">
                                    <b>
                                        Blended finance is one critical approach
                                        to mobilize new sources of capital for
                                        the SDGs, however it can only address a
                                        subset of SDG targets that are
                                        investable.
                                    </b>{" "}
                                    For example, blended finance is highly
                                    aligned with goals such as Goal 8 (Decent
                                    Work and Economic Growth) and Goal 13
                                    (Climate Action), while less aligned with
                                    SDGs such as Goal 16 (Peace, Justice and
                                    Strong Institutions).
                                </div>
                            </section>

                            <section className="mb-4" id="characteristics">
                                <h4 className="section-title mb-3 tw-text-2xl">
                                    Characteristics
                                </h4>

                                <div className="mb-2">
                                    Blended finance transactions should have
                                    three signature characteristics:
                                </div>
                                <ol>
                                    <li className="mb-2">
                                        <b>
                                            The transaction contributes towards
                                            achieving the SDGs.
                                        </b>{" "}
                                        However, not every participant needs to
                                        have that development objective. Private
                                        investors in a blended finance structure
                                        may simply be seeking a market-rate
                                        financial return.
                                    </li>
                                    <li className="mb-2">
                                        <b>
                                            Overall, the transaction expects to
                                            yield a positive financial return.
                                        </b>{" "}
                                        Different investors in a blended finance
                                        structure will have different return
                                        expectations, ranging from concessional
                                        to market-rate.
                                    </li>
                                    <li>
                                        <b>
                                            The public and/or philanthropic
                                            parties are catalytic.
                                        </b>{" "}
                                        The participation from these parties
                                        improves the risk/return profile of the
                                        transaction in order to attract
                                        participation from the private sector.
                                    </li>
                                </ol>
                            </section>

                            <section className="mb-4" id="archetypes">
                                <h4 className="section-title mb-3 tw-text-2xl">
                                    Archetypes
                                </h4>

                                <div className="mb-3">
                                    Blended finance is a structuring approach.
                                    Blended finance is not an investment
                                    approach, instrument, or end solution.
                                    Convergence identifies four common blended
                                    finance structures:
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-6 col-sm">
                                        <div className="archetypeBox">
                                            <img
                                                src="/static/archetype-1.png"
                                                alt="SDG Poster"
                                                className="archetypeImage"
                                            />
                                            <div>
                                                Public or philanthropic
                                                investors provide funds on
                                                below-market terms within the
                                                capital structure to lower the
                                                overall cost of capital or to
                                                provide an additional layer of
                                                protection to private investors
                                                (referred to as{" "}
                                                <b>concessional capital</b> in
                                                this primer).
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm">
                                        <div className="archetypeBox">
                                            <img
                                                src="/static/archetype-2.png"
                                                alt="SDG Poster"
                                                className="archetypeImage"
                                            />
                                            <div>
                                                Public or philanthropic
                                                investors provide credit
                                                enhancement through guarantees
                                                or insurance on below-market
                                                terms (referred to as{" "}
                                                <b>
                                                    <a
                                                        href="https://www.convergence.finance/news-and-events/news/5sx7ivKz7eNwZBlLNRfN87/view"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        guarantee
                                                    </a>{" "}
                                                    / risk insurance
                                                </b>{" "}
                                                in this primer).
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-md-6 col-sm">
                                        <div className="archetypeBox">
                                            <img
                                                src="/static/archetype-3.png"
                                                alt="SDG Poster"
                                                className="archetypeImage"
                                            />
                                            <div>
                                                Transaction is associated with a
                                                grant-funded technical
                                                assistance facility that can be
                                                utilized pre- or post-investment
                                                to strengthen commercial
                                                viability and developmental
                                                impact (referred to as{" "}
                                                <strong>
                                                    <a
                                                        href="https://www.convergence.finance/news-and-events/news/7B8zuo5GNd6tVn5jBlhFAr/view"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        technical assistance
                                                        funds
                                                    </a>
                                                </strong>{" "}
                                                in this primer).
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm">
                                        <div className="archetypeBox">
                                            <img
                                                src="/static/archetype-4.png"
                                                alt="SDG Poster"
                                                className="archetypeImage"
                                            />
                                            <div>
                                                Transaction design or
                                                preparation is grant funded
                                                (including project preparation
                                                or design-stage grants)
                                                (referred to as{" "}
                                                <strong>
                                                    <a
                                                        href="https://www.convergence.finance/design-funding"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        design-stage grants
                                                    </a>
                                                </strong>{" "}
                                                in this primer).
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            {stats && Object.keys(stats).length > 0 && (
                                <React.Fragment>
                                    <section className="mb-4" id="market-size">
                                        <h4 className="section-title mb-3 tw-text-2xl">
                                            Market Size
                                        </h4>
                                        <div className={"number"}>
                                            {!loading &&
                                                stats
                                                    .historicalDealSummaryAnalysis
                                                    .totalDealVolume && (
                                                    <CountUp
                                                        duration={6}
                                                        start={0}
                                                        end={parseInt(
                                                            formatLargeNumber(
                                                                stats
                                                                    .historicalDealSummaryAnalysis
                                                                    .totalDealVolume,
                                                                false
                                                            ),
                                                            10
                                                        )}
                                                        prefix={"$"}
                                                        suffix={"B"}
                                                    />
                                                )}
                                            {loading && <Spinner />}
                                        </div>
                                        <div className="mb-3">
                                            <b>
                                                Blended finance has mobilized
                                                approximately $
                                                {formatLargeNumber(
                                                    stats
                                                        .historicalDealSummaryAnalysis
                                                        .totalDealVolume,
                                                    false
                                                )}{" "}
                                                billion in capital towards
                                                sustainable development in
                                                developing countries to-date.
                                            </b>{" "}
                                            Convergence has identified
                                            approximately 6,800 financial
                                            commitments to these blended finance
                                            transactions.
                                        </div>

                                        <div className={"mb-3"}>
                                            <LineColumnChart
                                                id={
                                                    "convergence-bf-transactions-activity-growth"
                                                }
                                                title={
                                                    "Growth of Annual Blended Finance Activities"
                                                }
                                                data={[
                                                    {
                                                        name:
                                                            "Number of Transactions Closed",
                                                        type: "column",
                                                        data: stats?.historicalDealSummaryAnalysis?.marketGrowth
                                                            ?.reduce(
                                                                (
                                                                    totals,
                                                                    year,
                                                                    index
                                                                ) => {
                                                                    totals.push(
                                                                        ((totals.length &&
                                                                            totals[
                                                                                index -
                                                                                    1
                                                                            ]) ||
                                                                            0) +
                                                                            year.count
                                                                    );
                                                                    return totals;
                                                                },
                                                                []
                                                            )
                                                            ?.slice(-10)
                                                    },
                                                    {
                                                        name:
                                                            "Total Capital Committed",
                                                        type: "area",
                                                        color:
                                                            theme
                                                                .chartColours[1],
                                                        data: stats?.historicalDealSummaryAnalysis?.marketGrowth
                                                            ?.map(
                                                                (year) =>
                                                                    year.cumulativeTotal
                                                            )
                                                            ?.slice(-10)
                                                    }
                                                ]}
                                                xAxis={{
                                                    labels: stats?.historicalDealSummaryAnalysis?.marketGrowth
                                                        ?.map(
                                                            (year) => year.key
                                                        )
                                                        ?.slice(-10),
                                                    title:
                                                        "Percentage of blended finance transactions by total size"
                                                }}
                                                colors={[
                                                    "#337ab7",
                                                    "#CCCCCC",
                                                    "#333333"
                                                ]}
                                                // TS FOUND ERROR: no prop horizontal
                                                // TS CODE CHANGE
                                                // horizontal={true}
                                                options={{
                                                    source: "Convergence",
                                                    copyright: true
                                                }}
                                            />
                                        </div>

                                        <div className="mb-3">
                                            Convergence curates and maintains
                                            the largest and most detailed
                                            database of historical blended
                                            finance transactions to help build
                                            the evidence base for blended
                                            finance. Given the current state of
                                            information reporting and sharing,
                                            it is not possible for this database
                                            to be fully comprehensive, but it is
                                            the best repository globally to
                                            understand blended finance scale and
                                            trends. Convergence continues to
                                            build out this database to draw
                                            better insights about the market and
                                            disseminates this information to the
                                            development and finance communities
                                            to improve the efficiency and
                                            effectiveness of blended finance to
                                            achieve the SDGs.
                                        </div>
                                    </section>

                                    <section
                                        className="mb-4"
                                        id="deal-sizes-and-types"
                                    >
                                        <h4 className="section-title mb-3 tw-text-2xl">
                                            Deal Sizes and Types
                                        </h4>

                                        <div className={"chart"}>
                                            {loading && <Spinner />}
                                            {!loading &&
                                                stats.historicalDealSummaryAnalysis &&
                                                stats
                                                    .historicalDealSummaryAnalysis
                                                    .dealSizeByRange && (
                                                    <DealSizesByRange
                                                        dealSizeByRange={
                                                            stats
                                                                .historicalDealSummaryAnalysis
                                                                .dealSizeByRange
                                                        }
                                                    />
                                                )}
                                        </div>

                                        <div className="mb-5">
                                            Blended finance transactions range
                                            considerably in size, from a minimum
                                            of $110,000 to a maximum of $8
                                            billion. The median blended finance
                                            transaction has been $64 million in
                                            total size (2010-2018).
                                        </div>

                                        <div className={"chart"}>
                                            {loading && <Spinner />}
                                            {!loading &&
                                                stats.historicalDealSummaryAnalysis &&
                                                stats
                                                    .historicalDealSummaryAnalysis
                                                    .numberOfDealsByType && (
                                                    <NumberOfDealsByVehicleType
                                                        numberOfDealsByType={
                                                            stats
                                                                .historicalDealSummaryAnalysis
                                                                .numberOfDealsByType
                                                        }
                                                    />
                                                )}
                                        </div>

                                        <div className="mb-5">
                                            Funds (e.g., equity funds, debt
                                            funds, and funds-of-funds) have
                                            consistently accounted for the
                                            largest share of blended finance
                                            transactions, although we have seen
                                            more diversification across
                                            transaction types in recent years.
                                        </div>
                                    </section>

                                    <section
                                        className="mb-4"
                                        id="blending-trends"
                                    >
                                        <h4 className="section-title mb-3 tw-text-2xl">
                                            Blending Trends
                                        </h4>

                                        <div className={"chart"}>
                                            {loading && <Spinner />}
                                            {!loading &&
                                                stats.historicalDealSummaryAnalysis &&
                                                stats
                                                    .historicalDealSummaryAnalysis
                                                    .numberOfDealsByBlendingArchetype && (
                                                    <NumberOfDealsByBlendingArchetypes
                                                        numberOfDealsByBlendingArchetype={
                                                            stats
                                                                .historicalDealSummaryAnalysis
                                                                .numberOfDealsByBlendingArchetype
                                                        }
                                                    />
                                                )}
                                        </div>
                                        <div className="mb-5">
                                            Concessional debt or equity has been
                                            the most common archetype, including
                                            first-loss debt or equity,
                                            investment-stage grants, and debt or
                                            equity that bears risk at
                                            below-market financial returns to
                                            mobilize private sector investment.
                                            There has been an increase in the
                                            use of both concessional debt or
                                            equity and guarantees or risk
                                            insurance in recent years.
                                        </div>
                                    </section>

                                    <section className="mb-4" id="regions">
                                        <h4 className="section-title mb-3 tw-text-2xl">
                                            Regions
                                        </h4>

                                        <div className={"mb-3"}>
                                            {loading && <Spinner />}
                                            {!loading &&
                                                stats.historicalDealSummaryAnalysis &&
                                                stats
                                                    .historicalDealSummaryAnalysis
                                                    .numberOfDealsByRegion && (
                                                    <NumberOfDealsByRegion
                                                        numberOfDealsByRegion={
                                                            stats
                                                                .historicalDealSummaryAnalysis
                                                                .numberOfDealsByRegion
                                                        }
                                                    />
                                                )}
                                        </div>

                                        <div className="mb-5">
                                            Sub-Saharan Africa has been the most
                                            frequently targeted region in
                                            blended finance transactions. In
                                            recent years, we’ve seen Asia and
                                            Latin America emerge as new
                                            frontiers for blended finance.
                                        </div>
                                    </section>

                                    <section className="mb-4" id="sectors">
                                        <h4 className="section-title mb-3 tw-text-2xl">
                                            Sectors
                                        </h4>

                                        <div className={"mb-3"}>
                                            {loading && <Spinner />}
                                            {!loading &&
                                                stats.historicalDealSummaryAnalysis &&
                                                stats
                                                    .historicalDealSummaryAnalysis
                                                    .numberOfDealsBySector && (
                                                    <NumberOfDealsBySector
                                                        numberOfDealsBySector={
                                                            stats
                                                                .historicalDealSummaryAnalysis
                                                                .numberOfDealsBySector
                                                        }
                                                    />
                                                )}
                                        </div>

                                        {!loading &&
                                            stats.historicalDealSummaryAnalysis &&
                                            stats.historicalDealSummaryAnalysis
                                                .numberOfDealsBySector && (
                                                <div className="mb-3">
                                                    {convertToCommandSeparatedList(
                                                        stats
                                                            .historicalDealSummaryAnalysis
                                                            .numberOfDealsBySector,
                                                        1
                                                    )}{" "}
                                                    has been the most frequently
                                                    targeted sector in blended
                                                    finance transactions,
                                                    followed by{" "}
                                                    {convertToCommandSeparatedList(
                                                        stats
                                                            .historicalDealSummaryAnalysis
                                                            .numberOfDealsBySector,
                                                        1,
                                                        null,
                                                        null,
                                                        1
                                                    )}
                                                    . Generalist structures
                                                    targeting multiple segments
                                                    have also been common.
                                                </div>
                                            )}
                                    </section>

                                    <section
                                        className="mb-4"
                                        id="sdgs-and-impact"
                                    >
                                        <h4 className="section-title mb-3 tw-text-2xl">
                                            SDGs and Impact
                                        </h4>

                                        <div className={"mb-3"}>
                                            {loading && <Spinner />}
                                            {!loading &&
                                                stats.historicalDealSummaryAnalysis &&
                                                stats
                                                    .historicalDealSummaryAnalysis
                                                    .numberOfDealsBySDG && (
                                                    <NumberOfDealsBySDG
                                                        numberOfDealsBySDG={
                                                            stats
                                                                .historicalDealSummaryAnalysis
                                                                .numberOfDealsBySDG
                                                        }
                                                    />
                                                )}
                                        </div>

                                        {!loading &&
                                            stats.historicalDealSummaryAnalysis &&
                                            stats.historicalDealSummaryAnalysis
                                                .numberOfDealsBySDG && (
                                                <div className="mb-3">
                                                    {convertToCommandSeparatedList(
                                                        stats
                                                            .historicalDealSummaryAnalysis
                                                            .numberOfDealsBySDG,
                                                        4,
                                                        /(\d+): (.*)/,
                                                        "Goal $1 ($2)"
                                                    )}{" "}
                                                    have been the most
                                                    frequently targeted in
                                                    blended finance
                                                    transactions.
                                                </div>
                                            )}
                                    </section>

                                    <section
                                        className="mb-4"
                                        id="investors-overall"
                                    >
                                        <h4 className="section-title mb-3 tw-text-2xl">
                                            Investors
                                        </h4>

                                        <div className={"number"}>
                                            {!loading &&
                                                stats.investorSummaryAnalysis
                                                    .numberOfUniqueInvestors && (
                                                    <CountUp
                                                        duration={6}
                                                        start={0}
                                                        end={
                                                            stats
                                                                .investorSummaryAnalysis
                                                                .numberOfUniqueInvestors
                                                        }
                                                        suffix={" investors"}
                                                    />
                                                )}
                                            {loading && <Spinner />}
                                        </div>

                                        <div className="mb-5">
                                            In the database, over{" "}
                                            {Math.floor(
                                                stats.investorSummaryAnalysis
                                                    .numberOfUniqueInvestors /
                                                    10
                                            ) * 10}{" "}
                                            unique investors have participated
                                            in one or more blended finance
                                            transactions.
                                        </div>

                                        <div className={"chart"}>
                                            {loading && <Spinner />}
                                            {!loading &&
                                                stats.investorSummaryAnalysis
                                                    .numberOfUniqueInvestorsBySector && (
                                                    <NumberOfInvestorsBySector
                                                        numberOfUniqueInvestorsBySector={
                                                            stats
                                                                .investorSummaryAnalysis
                                                                .numberOfUniqueInvestorsBySector
                                                        }
                                                    />
                                                )}
                                        </div>

                                        <div className="mb-3">
                                            Nearly 2/3 of blended finance
                                            investors come from the private
                                            sector.
                                        </div>

                                        <div className="mb-3">
                                            ~20% investors have participated in
                                            three or more blended finance
                                            transactions. The majority of
                                            investors have only participated in
                                            one blended finance transaction.
                                        </div>
                                    </section>

                                    <section
                                        className="mb-4"
                                        id="private-investors"
                                    >
                                        <h4 className="section-title mb-3 tw-text-2xl">
                                            Private Investors
                                        </h4>

                                        <div className={"mb-3"}>
                                            {loading && <Spinner />}
                                            {!loading &&
                                                stats.investorSummaryAnalysis &&
                                                stats.investorSummaryAnalysis
                                                    .topPrivateInvestorsByName && (
                                                    <TopPrivateInvestors
                                                        topPrivateInvestorsByName={
                                                            stats
                                                                .investorSummaryAnalysis
                                                                .topPrivateInvestorsByName
                                                        }
                                                    />
                                                )}
                                        </div>

                                        {!loading &&
                                            stats.investorSummaryAnalysis &&
                                            stats.investorSummaryAnalysis
                                                .topPrivateInvestorsByName && (
                                                <div className="mb-3">
                                                    The most active private
                                                    investors in blended finance
                                                    have included{" "}
                                                    {convertToCommandSeparatedList(
                                                        stats
                                                            .investorSummaryAnalysis
                                                            .topPrivateInvestorsByName,
                                                        5
                                                    )}
                                                    . Many of the most active
                                                    private investors have an
                                                    explicit impact-mandate,
                                                    either as a whole or the
                                                    specific branch/unit with
                                                    the relevant focus (e.g.,
                                                    sustainable finance).
                                                </div>
                                            )}
                                    </section>
                                    <section
                                        className="mb-4"
                                        id="public-investors"
                                    >
                                        <h4 className="section-title mb-3 tw-text-2xl">
                                            Public Investors with a Development
                                            Mandate
                                        </h4>

                                        <div className={"mb-3"}>
                                            {loading && <Spinner />}
                                            {!loading &&
                                                stats.investorSummaryAnalysis &&
                                                stats.investorSummaryAnalysis
                                                    .topDevelopmentAgencyInvestorsByName && (
                                                    <MostActiveDevelopmentAgencies
                                                        loading={loading}
                                                        data={
                                                            stats
                                                                .investorSummaryAnalysis
                                                                .topDevelopmentAgencyInvestorsByName
                                                        }
                                                        title={
                                                            "Top Public Investors with a Development Mandate"
                                                        }
                                                        // @ts-ignore TODO
                                                        gradientColors={[
                                                            "#337ab7",
                                                            "#10283c"
                                                        ]}
                                                    />
                                                )}
                                        </div>
                                        <div className="mb-3">
                                            The most active public investors
                                            with a development mandate include
                                            development agencies and multi-donor
                                            funds like USAID and GuarantCo.
                                            These parties have participated in
                                            about half of all blended
                                            transactions and provide funding to
                                            blended transactions both directly
                                            and indirectly through their
                                            contributions to multilateral
                                            organizations, MDBs, DFIs, funds,
                                            and programs.
                                        </div>
                                    </section>
                                    <section
                                        className="mb-4 "
                                        id="mdb-dfi-investors"
                                    >
                                        <h4 className="section-title mb-3 tw-text-2xl">
                                            Public Investors with a Commercial
                                            Mandate
                                        </h4>

                                        <div className={"mb-3"}>
                                            {loading && <Spinner />}
                                            {!loading &&
                                                stats.investorSummaryAnalysis &&
                                                stats.investorSummaryAnalysis
                                                    .topMdbDfiInvestorsByName && (
                                                    <MostActiveMDBsAndDFIs
                                                        loading={loading}
                                                        data={
                                                            stats
                                                                .investorSummaryAnalysis
                                                                .topMdbDfiInvestorsByName
                                                        }
                                                        // @ts-ignore
                                                        gradientColors={[
                                                            "#337ab7",
                                                            "#10283c"
                                                        ]}
                                                        title={
                                                            "Top Public Investors with a Commercial Mandate"
                                                        }
                                                    />
                                                )}
                                        </div>
                                        <div className="mb-3">
                                            The most active public investors
                                            with a commercial mandate include
                                            Multilateral Development Banks
                                            (MDBs) and Development Finance
                                            Institutions (DFIs). MDBs and DFIs
                                            participate in blended finance
                                            transactions as i) providers of
                                            commercial capital, and to a lesser
                                            extent, as ii) providers of
                                            concessional capital, which they
                                            source from specific pools of donor
                                            funding (i.e., through the indirect
                                            blending activities of donor
                                            governments).
                                        </div>
                                    </section>
                                    <section
                                        className="mb-4 "
                                        id="philanthropic-investors"
                                    >
                                        <h4 className="section-title mb-3 tw-text-2xl">
                                            Philanthropic Investors
                                        </h4>

                                        <div className={"mb-3"}>
                                            {loading && <Spinner />}
                                            {!loading &&
                                                stats.investorSummaryAnalysis &&
                                                stats.investorSummaryAnalysis
                                                    .topPhilanthropicInvestorsByName && (
                                                    <TopPhilanthropicInvestors
                                                        topPhilanthropicInvestorsByName={
                                                            stats
                                                                .investorSummaryAnalysis
                                                                .topPhilanthropicInvestorsByName
                                                        }
                                                    />
                                                )}
                                        </div>

                                        {!loading &&
                                            stats.investorSummaryAnalysis &&
                                            stats.investorSummaryAnalysis
                                                .topPhilanthropicInvestorsByName && (
                                                <div className="mb-3">
                                                    The most active
                                                    philanthropic investors in
                                                    blended finance have
                                                    included{" "}
                                                    {convertToCommandSeparatedList(
                                                        stats
                                                            .investorSummaryAnalysis
                                                            .topPhilanthropicInvestorsByName,
                                                        4
                                                    )}
                                                    .
                                                </div>
                                            )}
                                    </section>
                                    <section className="mb-4" id="learn-more">
                                        <h4 className="section-title mb-3 tw-text-2xl">
                                            Learn More
                                        </h4>

                                        <div className="mb-2">
                                            Convergence members have access to:
                                        </div>

                                        <ul>
                                            <li className="mb-2">
                                                Detailed information for each
                                                blended finance transactions in
                                                the database.
                                            </li>
                                            <li className="mb-2">
                                                Detailed information on active
                                                investors in blended finance and
                                                their investment trends.
                                            </li>
                                            <li className="mb-2">
                                                Additional insights and analysis
                                                for specific investors, blending
                                                archetypes, SDGs, sectors, and
                                                regions.
                                            </li>
                                            <li className="mb-2">
                                                In-depth case studies for select
                                                transactions, describing the
                                                design process, structure,
                                                impact to-date, and summary
                                                learnings.
                                            </li>
                                            <li className="mb-2">
                                                Trend briefs and other research
                                                on the blended finance market.
                                            </li>
                                            <li className="mb-2">
                                                A range of additional products
                                                and services.
                                            </li>
                                        </ul>

                                        <div className="mb-2">
                                            Learn more about Convergence
                                            membership{" "}
                                            <Link
                                                routeKey={"membership"}
                                                label={"here"}
                                            />
                                            .
                                        </div>
                                    </section>
                                </React.Fragment>
                            )}
                        </article>
                    </div>
                </GeneralPage>
            </Authenticated>
        );
    }
}

export default withApollo(BlendedFinance);
